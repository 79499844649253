import { Routes, Route, NavLink } from "react-router-dom";
import WithdrawList from './src/screens/WithList/WithdrawList';
import BattleList from "./src/screens/BattleList/BattleList";
import CompletedBattleList from "./src/screens/BattleList/CompletedBattleList";
import WinnerList from "./src/screens/BattleList/WinnerList";
import LoserList from "./src/screens/BattleList/LoserList";
import './App.css';
import PurList from "./src/screens/WithList/PurList";

function App() {
  return (
    <div>
      <div className="header">
        <NavLink to="/" activeClassName="active">Withdraw</NavLink>
        <NavLink to="/number-list" activeClassName="active">Pending Tasks</NavLink>
        <NavLink to="/completed-list" activeClassName="active">Completed Tasks</NavLink>
        <NavLink to="/winner-list" activeClassName="active">Winner List</NavLink>
        <NavLink to="/loser-list" activeClassName="active">Loser List</NavLink>
        <NavLink to="/added-amounts" activeClassName="active">Added Amounts</NavLink>
      </div>
      <div className="content">
        <Routes>
          <Route path="/" element={<WithdrawList />} />
          <Route path="/number-list" element={<BattleList />} />
          <Route path="/completed-list" element={<CompletedBattleList />} />
          <Route path="/winner-list" element={<WinnerList />} />
          <Route path="/loser-list" element={<LoserList />} />
          <Route path="/added-amounts" element={<PurList />} />
        </Routes>
      </div>
    </div>
  );
}

export default App;
