import React, { useEffect, useState } from 'react';
import './style.css'; // Import your CSS file for styling

function WithdrawList() {
  // State to track completed payments
  const [completedPayments, setCompletedPayments] = useState([]);
  const [Lists, setLists] = useState();
  const [Loading, setLoading] = useState(false);

  // Function to mark a payment as done
  const markAsDone = (paymentId) => {
    console.log('asdfasf');
  };

  const List = () =>{
    var myHeaders = new Headers();
    myHeaders.append("Accept", "application/json");

    var requestOptions = {
      method: 'GET',
      headers: myHeaders,
      redirect: 'follow'
    };

    fetch("https://picpoll.online/api/v1/withdrawhistory", requestOptions)
      .then(response => response.json())
      .then(result => {
        const filData = result?.data?.newpurchase?.filter(payment => payment?.paymentStatus != true)
        setLists(filData)
        setLoading(true)
      })
      .catch(error => console.log('error', error));
  }

  const PaymentDone = (id) =>{
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      "paymentStatus": true,
    });

    var requestOptions = {
      method: 'PATCH',
      headers: myHeaders,
      body: raw,
      redirect: 'follow'
    };

    fetch(`https://picpoll.online/api/v1/withdrawhistory/${id}`, requestOptions)
      .then(response => response.json())
      .then(result => {
        window.location.reload();
        console.log(result)
      })
      .catch(error => console.log('error', error));
  }

  useEffect(()=>{
    List();
  },[])

  return (
    <div className="App">
      <header className="App-header">
        <h2>Withdraw List</h2>
        <div className='outer'>
        <table className="payment-table">
          <thead>
            <tr>
              <th>Email</th>
              <th>Amount</th>
              <th>UPI</th>
              <th>Status</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {Lists?.length ? Lists?.map((payment) => (
              <tr key={payment.customer_id?.id}>
                <td>{payment?.customer_id?.displayName}</td>
                <td>{payment.amount}</td>
                <td>{payment?.customer_id?.upi}</td>
                <td>{payment?.paymentStatus?.toString()}</td>
                <td>
                {
                  payment?.customer_id?.paymentStatus === true ? ( <button style={{opacity: .5}}>Done</button>) :  <button onClick={() => PaymentDone(payment?.id)}>Done</button>
                }
                </td>
              </tr>
            )) : 
            Loading == false ? 
            (<div style={{marginTop: '10px', textAlign: 'left'}}>Loading.....</div>) : (<div style={{marginTop: '10px', textAlign: 'left'}}>No data found</div>)
            }
          </tbody>
        </table>
        </div>
      </header>
    </div>
  );
}

export default WithdrawList;
