import React, { useEffect, useState } from 'react';
import './style.css'; // Import your CSS file for styling

function BattleList() {
  // State to track completed payments
  const [SetNumber, setSetNumber] = useState();
  const [Lists, setLists] = useState();
  const [Loading, setLoading] = useState(false);

  // Function to mark a payment as done
  const markAsDone = (paymentId) => {
    console.log('asdfasf');
  };

  const List = () =>{
    var requestOptions = {
        method: 'GET',
        redirect: 'follow'
      };
      
      fetch("https://picpoll.online/api/v1/battle", requestOptions)
        .then(response => response.json())
        .then(result => {
            const data = result?.data?.battles?.filter(i=> i.opened_number?.length == 0 || i.opened_number == null)
            setLists(data)
            setLoading(true)
        })
        .catch(error => console.log('error', error));
  }

  const PaymentDone = (id, status) =>{
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      "opened_number": SetNumber,
      "win_status": status,
    });

    var requestOptions = {
      method: 'PATCH',
      headers: myHeaders,
      body: raw,
      redirect: 'follow'
    };

    fetch(`https://picpoll.online/api/v1/battle/${id}`, requestOptions)
      .then(response => response.json())
      .then(result => {
        if(result?.status == "success"){
            window.location.reload();
        }
      })
      .catch(error => console.log('error', error));
  }

  useEffect(()=>{
    List();
  },[])

  return (
    <div className="App">
      <header className="App-header">
        <h2>Pending Games</h2>
        <div className='outer'>
          <table className="payment-table">
            <thead>
              <tr>
                <th>Name</th>
                <th>Selected Numbers</th>
                <th>Open Number</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {Lists?.length ? Lists?.map((payment) => (
                <tr key={payment.user_id?.id}>
                  <td>{payment?.user_id?.displayName}</td>
                  <td>{payment.selected_numbers?.map((i) => (
                      i + ','
                      ))}
                  </td>
                  <td><input type='text' onChange={(e)=>{setSetNumber(e.target.value)}}/></td>
                  <td>
                  <button onClick={() => PaymentDone(payment?.id, payment?.selected_numbers?.includes(Number(SetNumber)))}>Done</button>
                  </td>
                </tr>
              )) : 
              Loading == false ? 
              (<div style={{marginTop: '10px', textAlign: 'left'}}>Loading.....</div>) : (<div style={{marginTop: '10px', textAlign: 'left'}}>No data found</div>)
              }
            </tbody>
          </table>
        </div>
      </header>
    </div>
  );
}

export default BattleList;
